import { useAppSelector } from '@reduxs/hooks'
import { Tabs, TabsProps } from 'antd'

import { FC, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import classes from './styles.module.less'

interface Props {
    itemsTabs: TabsProps['items']
    tabName?: string
    activeKey: string | number
}

const TabsComponent: FC<Props> = ({ itemsTabs, activeKey, tabName }): JSX.Element => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { currentLang } = useAppSelector((state) => state.settings)
    const handleOnChange = (activeKey: string) => {
        searchParams.set(tabName || 'tab', activeKey)
        searchParams.delete('page')
        setSearchParams(searchParams)
    }

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            const activeTab = document.getElementsByClassName('ant-tabs-tab-active')[0]
            const currentWidth = activeTab.clientWidth
            const currentLeft = activeTab && (activeTab as HTMLElement)?.offsetLeft
            const tanInkBar = document.getElementsByClassName('ant-tabs-ink-bar')[0]
            tanInkBar.setAttribute('style', `left: ${currentLeft}px; width:${currentWidth}px `)
        })
        resizeObserver.observe(document.getElementsByClassName('ant-tabs-tab-active')[0])
        return () => resizeObserver.disconnect()
    }, [activeKey, currentLang])

    return (
        <div className={classes.root}>
            <Tabs className={classes.tabs} activeKey={String(activeKey)} items={itemsTabs} onChange={handleOnChange} />
        </div>
    )
}

export default TabsComponent
