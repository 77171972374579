/* eslint-disable camelcase */

import {
    checkFileSize,
    generateFormData,
    isErrorWithMessage,
    isFetchBaseQueryError,
    isValidFileExtension,
    isValidFileName
} from '@utils/helpers'
import { Typography, Upload, UploadProps } from 'antd'
import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import SelectedFile from '@assets/icons/selected-file.svg'
import classes from './styles.module.less'

import useSteps from '@hooks/useSteps'
import { STATUS_CODE } from '@constants/app'
import { useAppSelector } from '@reduxs/hooks'
import { ModalConfirm, Button } from '@components'
import useHandleDataResult from './hooks/useHandleDataResult'
import { Handle, PayloadData } from 'components/ModalConfirm/ModalConfirm'
import { useUploadFileMutation } from '@services/BulkTransferApp/UploadFileAPI'

const FileLoader = (): JSX.Element => {
    const { t: translate } = useTranslation()
    const modalRef = useRef<Handle | null>(null)

    const { steps, setSteps } = useSteps()
    const { currentLang } = useAppSelector((state) => state.settings)

    const [uploadFileMutate, { isLoading, data: dataResult, reset }] = useUploadFileMutation()

    const showErrorModal = useCallback(
        ({ prompt, title }: PayloadData) => {
            modalRef.current?.confirm({
                title: title || translate('messages.title.fileInValid'),
                prompt
            })
        },
        [translate]
    )

    const checkValidateFile = (name: string, size: number) => {
        const isValidExtension = isValidFileExtension(name)
        const isValidName = isValidFileName(name)
        const checkSize = checkFileSize(size)
        return { isValidExtension, isValidName, checkSize }
    }

    const beforeUpload: UploadProps['beforeUpload'] = async (file: File) => {
        const { isValidExtension, isValidName, checkSize } = checkValidateFile(file.name, file.size)
        if (!isValidName) {
            showErrorModal({
                title: translate('messages.title.fileNameInvalid'),
                prompt: translate('messages.error.FILE_NAME')
            })
        }
        if (!isValidExtension || !checkSize) {
            showErrorModal({ prompt: translate('messages.error.FILE_FORMAT', { size: '1Mb' }) })
        }
        return false
    }

    const onChange: UploadProps['onChange'] = ({ file, fileList }) => {
        const { name, uid, size, status } = file
        const { isValidExtension, isValidName, checkSize } = checkValidateFile(name, size as number)
        if (isValidExtension && checkSize && isValidName) {
            if (status !== 'uploading') {
                const file = fileList.find((item) => item.uid === uid) as any
                const newFileLocal = file.originFileObj
                setSteps({ ...steps, fileLocal: newFileLocal })
            }
        }
    }

    const handleFile = useCallback(
        async (file: File) => {
            try {
                const { formData } = generateFormData({ file, lang: currentLang.toUpperCase() })
                await uploadFileMutate(formData).unwrap()
            } catch (dataError) {
                setSteps({ ...steps, fileLocal: undefined })
                if (isFetchBaseQueryError(dataError)) {
                    showErrorModal({
                        title: translate('messages.title.unknownError'),
                        prompt: translate('messages.error.unknownError')
                    })
                } else if (isErrorWithMessage(dataError)) {
                    showErrorModal({
                        title: translate('messages.title.unknownError'),
                        prompt: dataError.message
                    })
                }
            }
        },
        [currentLang, uploadFileMutate, showErrorModal, translate, steps, setSteps]
    )

    useEffect(() => {
        // Trigger handle file when upload new file - step completed
        if (steps.fileLocal && !steps.file) handleFile(steps.fileLocal)
    }, [steps, handleFile])

    useEffect(() => {
        if (!isLoading && dataResult) {
            const { code, message } = dataResult
            if (code !== STATUS_CODE.SUCCESS) {
                setSteps({ ...steps, fileLocal: undefined })
                showErrorModal({ title: message.title, prompt: message.text })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataResult, showErrorModal, isLoading])

    useHandleDataResult(dataResult)

    return (
        <div className={classes.root}>
            {isLoading && <div className={classes.processing} />}
            <Upload
                id='upload-file'
                beforeUpload={beforeUpload}
                multiple={false}
                onChange={onChange}
                accept='.xls,.xlsx'
                className={classes.upload}
            >
                <div className={classes.content}>
                    {isLoading && (
                        <>
                            <img src={SelectedFile} alt='selected-file' />
                            <Typography className={classes.uploading}>
                                {translate('pages.bulkTransfer.uploading')}
                            </Typography>
                        </>
                    )}
                    {!isLoading && (
                        <>
                            <Button style={{ width: 190 }} type='primary'>
                                {translate('buttons.chooseFile')}
                            </Button>
                            <Typography className={classes.title}>
                                {translate('pages.bulkTransfer.dragFile')}{' '}
                            </Typography>
                            <Typography className={classes.subTitle}>
                                {translate('pages.bulkTransfer.formatFile')}
                            </Typography>
                        </>
                    )}
                </div>
            </Upload>

            <ModalConfirm ref={modalRef} width={600} error footer={false} resetCacheData={reset} />
        </div>
    )
}

export default FileLoader
