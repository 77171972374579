import { Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classes from './styles.module.less'

type Props = {
    totalItemsValid?: string
    totalAmountValid?: string
}

const TransactionStats = ({ totalAmountValid, totalItemsValid }: Props) => {
    const { t: translate } = useTranslation()
    return (
        <div className={classes.root}>
            <Typography className={classes.itemsValid}>
                {`${translate('pages.bulkTransfer.totalTransactionValid')}:`}
                <span>{totalItemsValid}</span>
            </Typography>
            <Typography className={classes.divider}>|</Typography>
            <Typography className={classes.amountValid}>
                {`${translate('pages.bulkTransfer.totalAmountValid')}:`}
                <span>{totalAmountValid}</span>
            </Typography>
        </div>
    )
}

export default TransactionStats
