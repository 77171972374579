import React, { FC } from 'react'
import { Button, ButtonProps } from 'antd'
import classes from './styles.module.less'

interface Props extends ButtonProps {
    disabled?: boolean
    onClick?: () => void
    children: React.ReactNode
}
const CustomButton: FC<Props> = ({ size = 'large', disabled, onClick, children, ...rest }): JSX.Element => {
    return (
        <div
            style={{ cursor: disabled ? 'no-drop' : 'pointer' }}
            className={classes.root}
            onClick={disabled ? undefined : onClick}
        >
            <Button disabled={disabled} size={size} {...rest}>
                {children}
            </Button>
        </div>
    )
}

export default CustomButton
