import { Typography } from 'antd'
import React from 'react'

type Props = {
    text: string
    className?: string
}
const TextSubTitle = ({ text, className, ...rest }: Props) => {
    return (
        <Typography {...rest} className={`text-sub-title ${className}`}>
            {text}
        </Typography>
    )
}

export default TextSubTitle
