import { Typography } from 'antd'
import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'

import useSteps from '@hooks/useSteps'
import { DEEP_LINK, LINK_QR_APP_VIB } from '@constants/app'

import classes from './styles.module.less'
import { FC } from 'react'

const GenerateQrCode: FC = (): JSX.Element => {
    const { t: translate } = useTranslation()
    const { steps } = useSteps()
    return (
        <>
            <div className={classes.scanQr}>
                <QRCode
                    size={120}
                    value={steps.tranMultiId ? DEEP_LINK + steps.tranMultiId : LINK_QR_APP_VIB}
                    renderAs='svg'
                    level='H'
                    imageSettings={{
                        src: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 20.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 40 40' style='enable-background:new 0 0 40 40;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FCB813;%7D .st1%7Bfill:%23F37920;%7D .st2%7Bfill:%23F6921E;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M28.9,4.8c-2.7,0-3.8,0.7-5.4,2.2l-3.4,3.7L16.8,7c-1.5-1.5-2.6-2.2-5.4-2.2h-4c0,0,6.2,7.7,7,8.6 c1.4,1.5,2.2,2.2,4.1,2.2h3.3c1.8,0,2.7-0.7,4.1-2.2c0.8-0.8,7-8.6,7-8.6S28.9,4.8,28.9,4.8z'/%3E%3Cpath class='st1' d='M26.7,14.9c-1.9,0.4-3,0.8-4,2.5l-0.8,1.4L21,20.1c-1,1.6-0.7,2.7-0.1,4.7c0.4,1.1,4,10.3,4,10.3l1.9-3.4 c1.4-2.3,1.4-3.7,0.8-5.8l-1.5-4.7l4.9-1.1c2.1-0.5,3.2-1.2,4.5-3.6l1.9-3.4C37.6,13.1,27.8,14.6,26.7,14.9'/%3E%3Cpath class='st2' d='M19.1,20.1l-0.8-1.4l-0.8-1.4c-1-1.6-2.1-2.1-4-2.5c-1.1-0.3-11-1.8-11-1.8l1.9,3.4c1.4,2.3,2.5,3,4.5,3.6 l5.1,1.1L12.5,26C12,28,12,29.4,13.3,31.7l1.9,3.4c0,0,3.6-9.2,4-10.3C19.9,22.8,20.1,21.7,19.1,20.1'/%3E%3C/g%3E%3C/svg%3E",
                        height: 20,
                        width: 20,
                        excavate: true
                    }}
                />
                <div className={classes.scanAnimated} />
            </div>
            <div style={{ marginTop: 20 }}>
                <Typography style={{ textAlign: 'center' }}>
                    <b>{translate('pages.bulkTransfer.scanQr')}</b> {translate('pages.bulkTransfer.screen')} <br />{' '}
                    {translate('pages.bulkTransfer.camera')} <br /> {translate('pages.bulkTransfer.installApp')}
                </Typography>
            </div>
        </>
    )
}

export default GenerateQrCode
