import { Tooltip, Typography } from 'antd'
import React from 'react'

type Props = {
    text: string
}

const DescriptionTable = ({ text }: Props): JSX.Element => {
    return (
        <Tooltip title={text}>
            <Typography className='truncate' style={{ width: 180 }}>
                {text}
            </Typography>
        </Tooltip>
    )
}

export default DescriptionTable
