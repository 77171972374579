import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface InitialState {
    currentLang: string
}

const initialState: InitialState = {
    currentLang: 'vi'
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setCurrentLang: (state, { payload }: PayloadAction<string>) => {
            state.currentLang = payload
        }
    }
})

export const { setCurrentLang } = settingsSlice.actions
export default settingsSlice
