import { createContext } from 'react'
import { ListTransaction } from 'types/bulkTransfers'

export interface InitialState {
    file?: Array<ListTransaction>
    fileLocal: any
    purpose: string
    message?: string
    userName?: string
    tranMultiId?: string
    transactionId?: string
    totalAmountValid?: string
    isCompleted?: boolean
    isOTPAuthenticated?: boolean
    totalItems?: number
    totalItemValid?: string
    dataCompleted?: any
    checksum?: string
}

export const initialStateStep: InitialState = {
    file: undefined,
    userName: '',
    isOTPAuthenticated: false,
    isCompleted: false,
    purpose: '',
    fileLocal: null,
    totalItems: 0,
    transactionId: '',
    dataCompleted: null,
    message: '',
    totalItemValid: '',
    totalAmountValid: '',
    tranMultiId: '',
    checksum: ''
}

export type GlobalContent = {
    steps: InitialState
    setSteps: (values: InitialState) => void
    prev: () => void
    next: () => void
    setCurrentStep: (value: number) => void
}

const StepsContext = createContext<GlobalContent>({
    steps: initialStateStep,
    setSteps: (values) => {},
    prev: () => {},
    next: () => {},
    setCurrentStep: (values) => {}
})

export default StepsContext
