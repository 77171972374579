import { Row, Typography } from 'antd'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ImageStepOne from '@assets/images/app-step1.png'
import ImageStepTwo from '@assets/images/app-step2.png'
import ImageStepThree from '@assets/images/app-step3.png'
import ImageStepOneEn from '@assets/images/app-step1-en.png'
import ImageStepTwoEn from '@assets/images/app-step2-en.png'
import ImageStepThreeEn from '@assets/images/app-step3-en.png'
import ImageAppStepperItem from './ImageAppStepperItem'

import classes from './styles.module.less'
import { useAppSelector } from '@reduxs/hooks'
import { CURRENT_LANG } from '@constants/app'

const ImageAppStepper = (): JSX.Element => {
    const { t: translate } = useTranslation()
    const { currentLang } = useAppSelector((state) => state.settings)

    const stepsApp = useMemo(
        () => [
            {
                step: 1,
                descriptions: translate('pages.bulkTransfer.chooseToTransfer'),
                sourceImage: currentLang === CURRENT_LANG.EN ? ImageStepOneEn : ImageStepOne
            },
            {
                step: 2,
                descriptions: translate('pages.bulkTransfer.chooseFromExcel'),
                sourceImage: currentLang === CURRENT_LANG.EN ? ImageStepTwoEn : ImageStepTwo,
                marginLeft: 20
            },
            {
                step: 3,
                descriptions: translate('pages.bulkTransfer.chooseTransactionPending'),
                sourceImage: currentLang === CURRENT_LANG.EN ? ImageStepThreeEn : ImageStepThree
            }
        ],
        [translate, currentLang]
    )
    return (
        <>
            <Typography className={classes.textOr}>{translate('pages.bulkTransfer.or')}</Typography>
            <Row justify='space-between'>
                {stepsApp.map((item) => (
                    <ImageAppStepperItem
                        key={`step-${item.step}`}
                        step={item.step}
                        sourceImage={item.sourceImage}
                        description={item.descriptions}
                        marginLeft={item.marginLeft}
                    />
                ))}
            </Row>
        </>
    )
}

export default ImageAppStepper
