import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '@services/CacheFileApp/baseQuery'
import { PostFileFormDataBody } from 'types/bulkTransfers'

interface PayloadHeader {
    app_key: string
    session_id: string
    access_token: string
}

interface PayloadCacheFile {
    body: PostFileFormDataBody
    payloadHeader: PayloadHeader
}

export const CacheUploadFileAPI = createApi({
    reducerPath: 'cacheFileAPI',
    baseQuery,
    endpoints: (builder) => ({
        cacheFile: builder.mutation<{}, PayloadCacheFile>({
            query: ({ body, payloadHeader }) => ({
                url: 'files/upload-external',
                method: 'POST',
                body,
                headers: {
                    app_key: payloadHeader?.app_key,
                    session_id: payloadHeader?.session_id,
                    access_token: payloadHeader?.access_token
                }
            })
        })
    })
})

export const { useCacheFileMutation } = CacheUploadFileAPI
