export enum CONFIG_APP {
    SETTING_REGEX = 'SETTING_REGEX',
    LANG = 'LANG',
    AUTH_INFO = 'AUTH_INFO'
}

export const CURRENT_LANG = {
    VI: 'vi',
    EN: 'en'
}

export const LINK_QR_APP_VIB = 'https://vibcheckout.onelink.me/kSjU/j407gnst'

export const DEEP_LINK =
    'https://vibcheckout.onelink.me/kSjU/uqqplnmr?deep_link_value=transfer&deep_link_sub1=TRANSFERBULKEXCEL&deep_link_sub2='

export const TEXT_LENGTH_RECEIVER = 35
export const TEXT_LENGTH_ACCOUNT_NO = 15
export const TEXT_LENGTH_ORDER = 3

export const ENVIRONMENT = {
    DEV: 'dev',
    FAT: 'fat',
    UAT: 'uat',
    PREPROD: 'preprod',
    PROD: 'prod'
}

export const STATUS_CODE = {
    SUCCESS: '000000',
    UN_AUTHORIZED: '000401',
    INVALID_INFO: '000406',
    TRANSACTION_PENDING: '000410',
    WARNING_APPROVE: '000411',
    TRANSACTION_TIME_OUT: '000402'
}

export const API_SERVICE = 'vibcheckout-transfer-webapi/v1/api'

export const FILE_EXTENSION = {
    SIZE: 1,
    LIST_EXTENSION: ['.xls', '.xlsx']
}

export const PAGE_SIZE = 10
export const EXPIRED_SECONDS = 180

export const TRAN_AMOUNT = {
    MIN: 10000,
    MAX: 499999999
}

export enum STEP {
    LOAD_FILE = 0,
    BULK_FILE_DETAILS = 1,
    OTP_AUTHENTICATE = 2,
    COMPLETED = 3
}

export const STATUS_TABLE_VALUE = {
    EMPTY: '',
    ALL: 'ALL',
    VALID: 'VALID',
    INVALID: 'INVALID'
}

export const PATTERN = {
    USER_NAME: /^[0-9a-zA-Z_]{6,20}$/,
    ALLOW_ONLY_NUMBER: /^[0-9,]*$/,
    PHONE: /^(03|05|07|08|09)+([0-9]{8})$/,
    FORMAT_AMOUNT: /^(\d{1,3}(,\d{3})*|\d{1,3}(.\d{3})*|(\d+))(\.\d+)?$/, // allow format: xxxxxx , xxx.xxx.xxx , xxx,xxx,xxx
    FORMAT_DATE: /(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/((19|20)\d\d)/,
    FILE_NAME:
        /^[0-9a-zA-ZàáảãạâầấẩẫậăằắẳẵặèéẻẽẹêềếểễệđìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹỵÀÁẢÃẠÂẦẤẨẪẬĂẰẮẲẴẶÈÉẺẼẸÊỀẾỂỄỆĐÌÍỈĨỊÒÓỎÕỌÔỒỐỔỖỘƠỜỚỞỠỢÙÚỦŨỤƯỪỨỬỮỰỲÝỶỸỴÂĂĐÔƠƯ .\-_()]{1,35}$/,
    TEXT_VIETNAMESE_NOT_SPECIAL:
        /^[0-9a-zA-ZàáảãạâầấẩẫậăằắẳẵặèéẻẽẹêềếểễệđìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹỵÀÁẢÃẠÂẦẤẨẪẬĂẰẮẲẴẶÈÉẺẼẸÊỀẾỂỄỆĐÌÍỈĨỊÒÓỎÕỌÔỒỐỔỖỘƠỜỚỞỠỢÙÚỦŨỤƯỪỨỬỮỰỲÝỶỸỴÂĂĐÔƠƯ ,.\-+():\\_&?#]*$/,
    ROW_LABEL:
        /^(?!\s*$)[0-9a-zA-ZàáảãạâầấẩẫậăằắẳẵặèéẻẽẹêềếểễệđìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹỵÀÁẢÃẠÂẦẤẨẪẬĂẰẮẲẴẶÈÉẺẼẸÊỀẾỂỄỆĐÌÍỈĨỊÒÓỎÕỌÔỒỐỔỖỘƠỜỚỞỠỢÙÚỦŨỤƯỪỨỬỮỰỲÝỶỸỴÂĂĐÔƠƯ ,.\-+():\\_&?]{1,160}$/,
    BANKING_NAME:
        /^(?!\s*$)[0-9a-zA-ZàáảãạâầấẩẫậăằắẳẵặèéẻẽẹêềếểễệđìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹỵÀÁẢÃẠÂẦẤẨẪẬĂẰẮẲẴẶÈÉẺẼẸÊỀẾỂỄỆĐÌÍỈĨỊÒÓỎÕỌÔỒỐỔỖỘƠỜỚỞỠỢÙÚỦŨỤƯỪỨỬỮỰỲÝỶỸỴÂĂĐÔƠƯ ,.\-+():\\_&?]{1,70}$/,
    TRAN_DESCRIPTION:
        /^(?!\s*$)[0-9a-zA-ZàáảãạâầấẩẫậăằắẳẵặèéẻẽẹêềếểễệđìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹỵÀÁẢÃẠÂẦẤẨẪẬĂẰẮẲẴẶÈÉẺẼẸÊỀẾỂỄỆĐÌÍỈĨỊÒÓỎÕỌÔỒỐỔỖỘƠỜỚỞỠỢÙÚỦŨỤƯỪỨỬỮỰỲÝỶỸỴÂĂĐÔƠƯ ,.\-+():\\_&?]{1,120}$/,
    PURPOSE_TRANSFERS:
        /^(?!\s*$)[0-9a-zA-ZàáảãạâầấẩẫậăằắẳẵặèéẻẽẹêềếểễệđìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹỵÀÁẢÃẠÂẦẤẨẪẬĂẰẮẲẴẶÈÉẺẼẸÊỀẾỂỄỆĐÌÍỈĨỊÒÓỎÕỌÔỒỐỔỖỘƠỜỚỞỠỢÙÚỦŨỤƯỪỨỬỮỰỲÝỶỸỴÂĂĐÔƠƯ ,.\-+():\\_&?]{1,35}$/
}
