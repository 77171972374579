import { useState } from 'react'

function useModal() {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const handleOpen = (): void => setIsOpen(true)

    const handleClose = (): void => setIsOpen(false)

    return { isOpen, setIsOpen, handleClose, handleOpen }
}

export default useModal
