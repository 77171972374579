import { Modal, Typography } from 'antd'
import React, { useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingOutlined } from '@ant-design/icons'
import CloseIcon from '@assets/icons/close-modal.svg'

import classes from './styles.module.less'
import Button from '../Button'
import IconModal from '../IconModal'
import useModal from '@hooks/useModal'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@routes/index'

type Props = {
    width: number
    footer: boolean
    error?: boolean
    success?: boolean
    isProcessing?: boolean
    resetCacheData?: () => void
}

export type PayloadData = {
    title?: string
    prompt?: string
    btnText?: string
    shouldResetTab?: boolean
    action?: () => void
}

export type Handle = {
    confirm: (data: PayloadData) => void
    handleClose: () => void
}

const styleButtonProps = {
    style: {
        display: 'none'
    }
}

const ModalConfirm = React.forwardRef((props: Props, ref) => {
    const navigate = useNavigate()
    const { width = 400, footer, success, error, isProcessing, resetCacheData, ...rest } = props

    const { t: translate } = useTranslation()

    const [data, setData] = useState<PayloadData | null>(null)

    const { isOpen, handleOpen, handleClose } = useModal()

    const onCancel = (): void => {
        handleClose()
        resetCacheData && resetCacheData()
        data?.shouldResetTab && navigate(ROUTES.HOME)
    }

    const confirm = (data: PayloadData) => {
        setData(data)
        handleOpen()
    }

    useImperativeHandle(ref, () => ({
        confirm,
        handleClose
    }))

    return (
        <div className={classes.root}>
            <Modal
                open={isOpen}
                onOk={handleClose}
                onCancel={onCancel}
                width={width}
                okButtonProps={styleButtonProps}
                cancelButtonProps={styleButtonProps}
                closeIcon={<img src={CloseIcon} alt='icon' />}
                footer={
                    footer
                        ? [
                              <div
                                  style={{
                                      padding: '5px 0',
                                      display: 'flex',
                                      justifyContent: 'flex-end'
                                  }}
                                  key='button-verify'
                              >
                                  <Button size='middle' disabled={isProcessing} onClick={handleClose}>
                                      {translate('buttons.cancel')}
                                  </Button>
                                  <Button
                                      disabled={isProcessing}
                                      style={{ marginLeft: 10 }}
                                      type='primary'
                                      size='middle'
                                      onClick={data?.action}
                                  >
                                      {isProcessing && <LoadingOutlined />}{' '}
                                      {data?.btnText || translate('buttons.accept')}
                                  </Button>
                              </div>
                          ]
                        : false
                }
                centered
                {...rest}
            >
                <div className={classes.modalContent}>
                    <IconModal success={success} error={error} />
                    <div>
                        {!!data?.title && <Typography className={classes.title}>{data.title}</Typography>}
                        {!!data?.prompt && <Typography className={classes.prompt}>{data.prompt}</Typography>}
                    </div>
                </div>
            </Modal>
        </div>
    )
})
ModalConfirm.displayName = 'ModalConfirm'
export default ModalConfirm
