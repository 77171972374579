import React, { FC } from 'react'
import { Col, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import useSteps from '@hooks/useSteps'
import classes from './styles.module.less'

const TransactionStats: FC = (): JSX.Element => {
    const { t: translate } = useTranslation()
    const { steps } = useSteps()

    return (
        <Row gutter={[20, 20]}>
            <Col xs={12} sm={8} md={8} xl={12} style={{ paddingTop: 10 }}>
                <Typography className={classes.completedLabel}>
                    {translate('pages.bulkTransfer.totalTransaction')}
                </Typography>
            </Col>
            <Col xs={12} sm={8} md={16} xl={12} style={{ paddingTop: 10 }}>
                <Typography className={classes.completedValue}>{steps.dataCompleted?.total_tran || 0}</Typography>
            </Col>
            <Col xs={12} sm={8} md={8} xl={12}>
                <Typography className={classes.completedLabel}>
                    {translate('pages.bulkTransfer.transactionValid')}
                </Typography>
            </Col>
            <Col xs={12} sm={8} md={16} xl={12}>
                <Typography className={classes.completedValue}>
                    {steps.dataCompleted?.total_success_tran || 0}
                </Typography>
            </Col>
            <Col xs={12} sm={8} md={8} xl={12}>
                <Typography className={classes.completedLabel}>
                    {translate('pages.bulkTransfer.transactionInvalid')}
                </Typography>
            </Col>
            <Col xs={12} sm={8} md={16} xl={12}>
                <Typography className={classes.completedValue}>{steps.dataCompleted?.total_fail_tran || 0}</Typography>
            </Col>
            <Col xs={14} sm={8} md={8} xl={12}>
                <Typography className={classes.completedLabel}>
                    {translate('pages.bulkTransfer.totalMoneyTransactionValid')}
                </Typography>
            </Col>
            <Col xs={10} sm={8} md={16} xl={12}>
                <Typography className={classes.completedValue}>
                    {steps.dataCompleted?.total_amount_name || 0}
                </Typography>
            </Col>
        </Row>
    )
}

export default TransactionStats
