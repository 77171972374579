import { Typography } from 'antd'
import React from 'react'

type Props = {
    text: string
}
const TextTitle = ({ text }: Props) => {
    return <Typography className='text-title'>{text}</Typography>
}

export default TextTitle
