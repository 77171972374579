import { useTranslation } from 'react-i18next'
import { Suspense, useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { ROUTES } from '@routes/index'
import { Backdrop } from '@components'
import { AppRoutes } from '@routes/AppRoutes'
import { useAppSelector } from '@reduxs/hooks'

const Router = () => {
    const { i18n } = useTranslation()
    const { currentLang } = useAppSelector((state) => state.settings)

    const ResolveRoutes = () => {
        return AppRoutes.map((route, index) => {
            return (
                <Route key={`routes-${index}`} path={`${route.path}`}>
                    <Route path={`${route.path}`} element={route.element} />
                </Route>
            )
        })
    }

    useEffect(() => {
        i18n.changeLanguage(currentLang)
    }, [currentLang, i18n])

    return (
        <>
            <BrowserRouter>
                <Suspense fallback={<Backdrop />}>
                    <Routes>
                        {ResolveRoutes()}
                        <Route path='/' element={<Navigate to={`${ROUTES.HOME}`} />} />
                        <Route path='*' element={<Navigate to={`${ROUTES.NOT_FOUND}`} />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </>
    )
}
export { Router }
