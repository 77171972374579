import React from 'react'
import { CheckOutlined } from '@ant-design/icons'
import ExclamationMark from '@assets/icons/exclamation-mark.svg'

import classes from './styles.module.less'

type Props = {
    success?: boolean
    error?: boolean
}

const IconModal = ({ success, error }: Props): JSX.Element => {
    return (
        <div className={success ? classes.success : classes.error}>
            {success && <CheckOutlined style={{ fontSize: '18px', color: '#27A87A' }} />}
            {error && <img src={ExclamationMark} alt='error-icon' />}
        </div>
    )
}

export default IconModal
