import React, { FC } from 'react'
import { Row, Col, Breadcrumb } from 'antd'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@reduxs/hooks'
import { CURRENT_LANG } from '@constants/app'
import Logo from '@assets/icons/logo.svg'
import classes from './styles.module.less'

type Props = {
    isNotShowBreadcrumb?: boolean
}

const Header: FC<Props> = ({ isNotShowBreadcrumb }): JSX.Element => {
    const { t: translate } = useTranslation()
    const { currentLang } = useAppSelector((state) => state.settings)

    const handleClick = () => {
        if (currentLang === CURRENT_LANG.EN) return window.location.replace('https://www.vib.com.vn/en/home')
        window.location.replace('https://www.vib.com.vn/vn/home')
    }
    return (
        <div className={classes.root}>
            <Row className={classes.main} align='middle'>
                <Col xs={0} sm={0} md={8}>
                    {!isNotShowBreadcrumb && (
                        <Breadcrumb>
                            <Breadcrumb.Item className={classes.breadcrumbItem}>
                                {translate('breadcrumb.bulkTransfer')}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    )}
                </Col>
                <Col xs={24} md={8} lg={8} sm={8}>
                    <div className={classes.logo}>
                        <div onClick={handleClick} style={{ cursor: 'pointer' }}>
                            <img src={Logo} width='70' alt='logo' />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Header
