import { Layout, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { FC, useCallback, useMemo } from 'react'

import VNIcon from '@assets/images/vn.png'
import EnglishIcon from '@assets/icons/english.svg'

import { setItem } from '@utils/localStorage'
import { useAppDispatch, useAppSelector } from '@reduxs/hooks'
import { setCurrentLang } from '@reduxs/slice/settings.slice'
import { CONFIG_APP, CURRENT_LANG, ENVIRONMENT } from '@constants/app'

import classes from './styles.module.less'
import packageJson from '../../../package.json'
import { useNavigate } from 'react-router-dom'

const { Content } = Layout

interface RenderCurrentLanguageProps {
    handleChange: (value: string) => void
    language: string
    icon: any
}

const RenderCurrentLanguage: FC<RenderCurrentLanguageProps> = ({ handleChange, language, icon }): JSX.Element => {
    const { t } = useTranslation()
    return (
        <div className={classes.currentLang} onClick={() => handleChange(language)}>
            <img src={icon} alt='icon' style={{ marginRight: 5 }} />
            <span>{t('common.currentLang')}</span>
        </div>
    )
}

const Footer = (): JSX.Element => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { t: translate } = useTranslation()

    const { currentLang } = useAppSelector((state) => state.settings)

    const handleChangeLanguage = useCallback(
        (value: string) => {
            let paths = window.location.pathname
            const search = window.location.search
            paths = paths.endsWith('/') ? paths.substring(0, paths.lastIndexOf('/')) : paths
            const lastText = paths.split('/')[paths.split('/').length - 1]
            const isMatch = [CURRENT_LANG.VI, CURRENT_LANG.EN].includes(lastText)

            if (isMatch) {
                const newPath = paths.substring(0, paths.lastIndexOf('/') + 1) + value + search
                navigate(newPath, { replace: true })
            } else {
                const newPath = paths.endsWith('/') ? `${paths}${value}` : `${paths}/${value}` + search
                navigate(newPath, { replace: true })
            }

            setItem(CONFIG_APP.LANG, value)
            dispatch(setCurrentLang(value))
        },
        [dispatch, navigate]
    )

    const language = useMemo(
        () => ({
            [CURRENT_LANG.EN]: (
                <RenderCurrentLanguage handleChange={handleChangeLanguage} language={CURRENT_LANG.VI} icon={VNIcon} />
            ),
            [CURRENT_LANG.VI]: (
                <RenderCurrentLanguage
                    handleChange={handleChangeLanguage}
                    language={CURRENT_LANG.EN}
                    icon={EnglishIcon}
                />
            )
        }),
        [handleChangeLanguage]
    )

    const version = process.env.REACT_APP_ENVIRONMENT !== ENVIRONMENT.PROD ? `- v${packageJson.version}` : ''

    return (
        <div className={classes.footer}>
            <Content className={classes.content}>
                <div className={classes.main}>
                    <Typography className={classes.copyRight}>
                        {`${translate('footer.copyRight')} ${version}`}
                    </Typography>
                    <div className={classes.locales}>
                        <div className={classes.textLocales}>{language[currentLang]}</div>
                    </div>
                </div>
            </Content>
        </div>
    )
}

export default Footer
