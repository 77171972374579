import { combineReducers } from '@reduxjs/toolkit'

import { UploadFileAPI } from '@services/BulkTransferApp/UploadFileAPI'
import { CacheUploadFileAPI } from '@services/CacheFileApp/CacheFileAPI'
import { MaintenanceAPI } from '@services/MaintenanceApp/MaintenanceAPI'

import SettingRedux from './slice/settings.slice'

const rootReducer = combineReducers({
    [SettingRedux.name]: SettingRedux.reducer,
    [UploadFileAPI.reducerPath]: UploadFileAPI.reducer,
    [CacheUploadFileAPI.reducerPath]: CacheUploadFileAPI.reducer,
    [MaintenanceAPI.reducerPath]: MaintenanceAPI.reducer
})

export default rootReducer
