import { Modal, Form, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@assets/icons/close-modal.svg'

import classes from './styles.module.less'
import Button from '../Button'
import FloatInput from '../FloatInput'
import { minTwoDigits } from '@utils/helpers'
import { PATTERN } from '@constants/app'

const styleButtonProps = {
    style: {
        display: 'none'
    }
}

type Props = {
    open: boolean
    onClose: () => void
    onFinish: (values: string) => void
}

const PurposeTransfersModal = ({ open, onClose, onFinish }: Props): JSX.Element => {
    const { t: translate } = useTranslation()

    const { t } = useTranslation()
    const [form] = Form.useForm()
    const [isDisabled, setIsDisabled] = useState<boolean>(false)

    const timer = new Date()
    const currentDay = timer.getDate()
    const currentMonth = timer.getMonth() + 1
    const currentYear = timer.getFullYear()

    const DEFAULT_VALUE = `${t('pages.bulkTransfer.defaultValuePurpose')} ${minTwoDigits(
        String(currentDay)
    )}-${minTwoDigits(String(currentMonth))}-${currentYear}`

    const onFieldsChange = (): void => {
        const hasErrors = form.getFieldsError().some(({ errors }) => errors.length)
        setIsDisabled(!!hasErrors)
    }

    const handleClose = (): void => {
        form.resetFields()
        setIsDisabled(false)
        onClose()
    }

    useEffect(() => {
        form.setFieldValue('content', DEFAULT_VALUE)
    }, [t, form, DEFAULT_VALUE, open])

    return (
        <Modal
            open={open}
            onCancel={handleClose}
            keyboard={false}
            okButtonProps={styleButtonProps}
            cancelButtonProps={styleButtonProps}
            width={600}
            closeIcon={<img src={CloseIcon} alt='icon' />}
            footer={[
                <div className={classes.footerModal} key='buttons-verify'>
                    <Button
                        type='primary'
                        className='modal__actions--ok'
                        onClick={() => onFinish(form.getFieldValue('content'))}
                        disabled={isDisabled}
                    >
                        {translate('buttons.continue')}
                    </Button>
                </div>
            ]}
            centered
        >
            <div className={classes.content}>
                <Typography className={classes.title}>{t('pages.bulkTransfer.purposeTransfer')}</Typography>
                <Typography className={classes.subTitle}>{t('pages.bulkTransfer.fieldNamePurpose')}</Typography>

                <Form
                    form={form}
                    onFieldsChange={onFieldsChange}
                    onFinish={() => onFinish(form.getFieldValue('content'))}
                    initialValues={{ content: DEFAULT_VALUE }}
                    className={classes.form}
                >
                    <Form.Item
                        name='content'
                        required
                        rules={[
                            {
                                required: true,
                                message: ''
                            },
                            {
                                pattern: PATTERN.PURPOSE_TRANSFERS,
                                message: ''
                            }
                        ]}
                        style={{ marginBottom: 12 }}
                    >
                        <FloatInput
                            autoComplete='off'
                            maxLength={35}
                            id='modal_reason'
                            initialValue={form.getFieldValue('content')}
                            label={t('inputs.form.bulkTransferPurpose')}
                        />
                    </Form.Item>
                    <Typography className={classes.notice}>{t('pages.bulkTransfer.notice')}:</Typography>
                    <Typography className={classes.promptNotice}>
                        {t('pages.bulkTransfer.noticePurposePrompt2')}
                    </Typography>
                </Form>
            </div>
        </Modal>
    )
}

export default PurposeTransfersModal
