import { FILE_EXTENSION, PATTERN } from '@constants/app'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

export const generateFormData = (data: any) => {
    const formData = new FormData()
    for (const key of Object.keys(data)) {
        formData.append(key, typeof data[key] === 'string' ? data[key]?.trim() : data[key])
    }
    return { formData }
}

export const minTwoDigits = (value: string) => (value ? ('0' + value).slice(-2) : '')

export const hasWhiteSpace = (s: string) => /\s/g.test(s)

export const getFileExtension = (filename: string) => {
    const parts = filename.split('.')
    return parts[parts.length - 1]
}

export const SheetJSFT = FILE_EXTENSION.LIST_EXTENSION.map((item) => '.' + item).join(',')

export const isValidFileExtension = (fileName: string) => {
    const getFileName = getFileExtension(fileName)
    const isValid = SheetJSFT.indexOf(getFileName)
    if (isValid > -1) return true
    return false
}

export const isValidFileName = (fileName: string) => {
    const name = fileName.split('.')
    name.splice(name.length - 1, 1)
    return !!name.join('.').match(PATTERN.FILE_NAME)
}

export const checkFileSize = (size: number | undefined, fileSize = FILE_EXTENSION.SIZE) => {
    if (size) {
        return size / 1024 / 1024 <= fileSize
    }
}

export const encodePhoneNumber = (phone: string) => {
    if (phone) {
        return phone.replace(/(\d{3})(\d{4})(\d{3})/, '$1xxxx$3')
    }
    return ''
}

export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError => {
    return typeof error === 'object' && error !== null && 'status' in error
}

export function isErrorWithMessage(error: unknown): error is { message: string } {
    return (
        typeof error === 'object' && error != null && 'message' in error && typeof (error as any).message === 'string'
    )
}
