import { CURRENT_LANG } from '@constants/app'
const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL

export const ROOT_URL = PUBLIC_URL?.endsWith('/') ? PUBLIC_URL : `${PUBLIC_URL}/`

const URL = {
    HEALTH: 'api/v1/health'
}

export const ROUTES = {
    HOME: ROOT_URL,
    HOME_VI: ROOT_URL + CURRENT_LANG.VI,
    HOME_EN: ROOT_URL + CURRENT_LANG.EN,
    HEALTH: ROOT_URL + URL.HEALTH,
    NOT_FOUND_VI: `${ROOT_URL}404/${CURRENT_LANG.VI}`,
    NOT_FOUND_EN: `${ROOT_URL}404/${CURRENT_LANG.EN}`,
    NOT_FOUND: `${ROOT_URL}404`,
    MAINTENANCE: `${ROOT_URL}maintenance`,
    MAINTENANCE_VI: `${ROOT_URL}maintenance/${CURRENT_LANG.VI}`,
    MAINTENANCE_EN: `${ROOT_URL}maintenance/${CURRENT_LANG.EN}`
}
