import { createApi } from '@reduxjs/toolkit/query/react'

import { CONFIG_APP } from '@constants/app'
import { baseQuery } from '@services/BulkTransferApp/baseQuery'
import { getSessionStorageItem } from '@utils/sessionStorage'
import { PostFileFormDataBody, ResponseData, BulkTransferData, SubmitData } from 'types/bulkTransfers'

const auth = getSessionStorageItem(CONFIG_APP.AUTH_INFO)

const headers = {
    access_token: auth?.accessToken,
    user_id: auth?.userId,
    app_key: auth?.appKey,
    session_id: auth?.sessionId
}

export const UploadFileAPI = createApi({
    reducerPath: 'uploadFileAPI',
    baseQuery,
    endpoints: (builder) => ({
        uploadFile: builder.mutation<ResponseData<BulkTransferData | undefined>, PostFileFormDataBody>({
            query: (body) => {
                // throw Error('kk')
                return {
                    url: 'upload',
                    method: 'POST',
                    body
                }
            }
        }),
        sendOtp: builder.mutation<ResponseData<{ environment: string; otp: string } | undefined>, PostFileFormDataBody>(
            {
                query: (body) => ({
                    url: 'otp',
                    method: 'POST',
                    body,
                    headers
                })
            }
        ),
        submitFile: builder.mutation<ResponseData<SubmitData>, PostFileFormDataBody>({
            query: (body) => ({
                url: 'submit',
                method: 'POST',
                body,
                headers
            })
        })
    })
})
export const { useUploadFileMutation, useSendOtpMutation, useSubmitFileMutation } = UploadFileAPI
