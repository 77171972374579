/**
 *
 * @param {*} key  Required, a string specifying the name of the key you want to set the value
 * @param {*} values Required, a string specifying the value of the key you want to set the value
 * @returns
 */

export const setSessionStorageItem = (key: string, values: any) => sessionStorage.setItem(key, JSON.stringify(values))

export const getSessionStorageItem = (key: string) => {
    if (typeof window !== 'undefined') {
        const value = sessionStorage.getItem(key)
        return value ? JSON.parse(value) : null
    }
}

export const removeSessionStorageItem = (key: string) => sessionStorage.removeItem(key)
