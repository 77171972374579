import { Spin } from 'antd'

import classes from './styles.module.less'

const Backdrop = () => {
    return (
        <div className={classes.root}>
            <Spin size='large' />
        </div>
    )
}

export default Backdrop
