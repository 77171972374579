import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'

import 'moment/locale/vi'
// import './less/index.less'
import vi_VN from 'antd/lib/locale/vi_VN'

import reportWebVitals from './reportWebVitals'
import { store } from '@reduxs/store'
import App from 'App'
import './i18n/index'
import './styles/index.less'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <Provider store={store}>
        <ConfigProvider locale={vi_VN}>
            <App />
        </ConfigProvider>
    </Provider>
)

reportWebVitals()
