import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '@services/MaintenanceApp/baseQuery'
import { ResponseData } from 'types/bulkTransfers'

export const MaintenanceAPI = createApi({
    reducerPath: 'MaintenanceAPI',
    baseQuery,
    endpoints: (builder) => ({
        getStatusMaintenance: builder.query<ResponseData<{ maintenance: boolean }>, void>({
            query: () => ({
                url: '/status',
                method: 'GET'
            })
        })
    })
})

export const { useLazyGetStatusMaintenanceQuery } = MaintenanceAPI
