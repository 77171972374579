import React, { useEffect } from 'react'
import { Footer, Header } from '@components'

import classes from './styles.module.less'
import { useAppSelector } from '@reduxs/hooks'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CONFIG_APP, CURRENT_LANG } from '@constants/app'
import { setItem } from '@utils/localStorage'
import { setCurrentLang } from '@reduxs/slice/settings.slice'
import { useLazyGetStatusMaintenanceQuery } from '@services/MaintenanceApp/MaintenanceAPI'
import { ROUTES } from '@routes/index'

type Props = {
    children: React.ReactNode
    title?: string
    isNotShowBreadcrumb?: boolean
}

const Layout = ({ children, isNotShowBreadcrumb }: Props): JSX.Element => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { currentLang } = useAppSelector((state) => state.settings)
    const [getStatusMaintenance, { data: maintenanceResult }] = useLazyGetStatusMaintenanceQuery()

    useEffect(() => {
        const paths = window.location.pathname.split('/')
        const language = paths[paths.length - 1]
        const isMatch = [CURRENT_LANG.VI, CURRENT_LANG.EN].includes(language)

        if (!isMatch) {
            setItem(CONFIG_APP.LANG, currentLang)
            dispatch(setCurrentLang(currentLang))
        } else {
            setItem(CONFIG_APP.LANG, language)
            dispatch(setCurrentLang(language))
        }
    }, [dispatch, currentLang])

    useEffect(() => {
        getStatusMaintenance()
    }, [getStatusMaintenance])

    useEffect(() => {
        if (maintenanceResult?.data.maintenance) return navigate(`${ROUTES.MAINTENANCE}/${currentLang}`)
    }, [maintenanceResult, navigate, currentLang])
    return (
        <div className={classes.body}>
            <Header isNotShowBreadcrumb={isNotShowBreadcrumb} />
            <div className={classes.root}>{children}</div>
            <Footer />
        </div>
    )
}

export default Layout
