import { Col, Typography } from 'antd'
import React, { FC } from 'react'
import classes from './styles.module.less'

type Props = {
    step: number
    description: string
    sourceImage: any
    marginLeft?: number | undefined
}

const ImageAppStepperItem: FC<Props> = ({ step, description, sourceImage, marginLeft = 0 }): JSX.Element => {
    return (
        <Col xs={24} md={12} lg={7}>
            <div className={classes.appItem}>
                <div className={classes.appStep}>
                    {step < 3 && <div className={classes.stepLine} />}
                    {step}
                </div>

                <Typography className={classes.stepDescription}>{description}</Typography>
                <div className={classes.image} style={{ backgroundImage: `url(${sourceImage})`, marginLeft }} />
            </div>
        </Col>
    )
}

export default ImageAppStepperItem
