import { useEffect } from 'react'
import useSteps from '@hooks/useSteps'
import { CONFIG_APP, STATUS_CODE } from '@constants/app'
import { setSessionStorageItem } from '@utils/sessionStorage'
import { BulkTransferData, ResponseData } from 'types/bulkTransfers'

const useHandleDataResult = (dataResult?: ResponseData<BulkTransferData | undefined>) => {
    const { data, code, message } = dataResult || {}

    const { steps, setSteps, next } = useSteps()

    useEffect(() => {
        const { list_tran, tran_id, session_id, total_amount_name, total_success_tran, checksum } = data || {}
        const newData = {
            message: message as any,
            transactionId: tran_id,
            totalItemValid: total_success_tran,
            totalAmountValid: total_amount_name,
            checksum
        }

        if (code === STATUS_CODE.SUCCESS) {
            const fileData = list_tran?.map((item, dataIndex: number) => ({
                ...item,
                key: `key-${dataIndex}-${item.acct_no}`
            }))
            const auth = { sessionId: session_id }
            setSessionStorageItem(CONFIG_APP.AUTH_INFO, auth)
            setSteps({ ...steps, file: fileData, ...newData })
            return next()
        }

        if (code === STATUS_CODE.INVALID_INFO && data?.list_tran) {
            setSteps({ ...steps, ...newData })
        }
    }, [data, code, message, next, setSteps, steps])
}
export default useHandleDataResult
