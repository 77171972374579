/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Input, InputRef } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import classes from './styles.module.less'

type Props = {
    onChange: (value: string[]) => void
    isReset: boolean
    otpDefault?: string[]
}

const BEHAVIOR = ['e', 'E', '+', '-', '.']

let currentOptIndex = 1

const OTPInput = ({ onChange, isReset, otpDefault }: Props): JSX.Element => {
    const inputRef = useRef<InputRef | null>(null)
    const [activeOtpIndex, setActiveOtpIndex] = useState(0)
    const [otp, setOtp] = useState(new Array(6).fill(''))

    useEffect(() => {
        inputRef.current?.focus()
    }, [activeOtpIndex])

    useEffect(() => {
        onChange(otp)
    }, [otp, onChange])

    useEffect(() => {
        if (isReset) {
            setOtp(new Array(6).fill(''))
            setActiveOtpIndex(0)
        }
    }, [isReset])

    useEffect(() => {
        if (otpDefault) {
            setOtp(otpDefault)
            setActiveOtpIndex(5)
        }
    }, [otpDefault])

    const handleOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        const newOtp = [...otp]
        if (Number(value) >= 0) {
            if (value) setActiveOtpIndex(currentOptIndex + 1)
            newOtp[currentOptIndex] = value.substring(value.length - 1)
            setOtp(newOtp)
        }
    }

    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        const { key } = event
        BEHAVIOR.includes(key) && event.preventDefault()
        currentOptIndex = index
        if (key === 'Backspace') {
            const newOtp = [...otp]
            newOtp[currentOptIndex] = ''
            setActiveOtpIndex(currentOptIndex - 1)
            setOtp(newOtp)
        }
        if (key === 'ArrowRight' && activeOtpIndex < 5) {
            setActiveOtpIndex(currentOptIndex + 1)
        }
        if (key === 'ArrowLeft' && activeOtpIndex > 0) {
            setActiveOtpIndex(currentOptIndex - 1)
        }
    }

    return (
        <div className={classes.root}>
            {otp.map((_, index) => {
                return (
                    <Input
                        key={`otp-${index}`}
                        ref={index === activeOtpIndex ? inputRef : null}
                        autoFocus={index === activeOtpIndex}
                        value={otp[index]}
                        type='number'
                        maxLength={1}

                        className={classes.otp}
                        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleOnKeyDown(event, index)}
                        onChange={handleOnchange}
                    />
                )
            })}
        </div>
    )
}

export default OTPInput
