/**
 *
 * @param {*} key  Required, a string specifying the name of the key you want to set the value
 * @param {*} values Required, a string specifying the value of the key you want to set the value
 * @returns
 */

export const setItem = (key: string, values: unknown) => localStorage.setItem(key, JSON.stringify(values))

export const getItem = (key: string) => {
    const value = localStorage.getItem(key)
    // if (value.startsWith("function"))return eval("(" + value + ")()")
    return value
}

export const removeItem = (key: string) => localStorage.removeItem(key)
