import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import vi from '@locales/vi'
import en from '@locales/en'

export const defaultNS = 'vi'

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false
    }
}

const resources = {
    vi,
    en
} as const

i18n.use(initReactI18next).init({
    resources,
    lng: 'vi',
    fallbackLng: 'vi',
    interpolation: {
        escapeValue: false
    },
    returnNull: false
})
