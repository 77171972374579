import { Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const StatusMessageTable = ({ record }: { record: any }): JSX.Element => {
    const { t: translate } = useTranslation()
    const [message, accountNo] = (record?.status || '').split(';')
    const messageKey = message ? `messages.error.${message}` : 'pages.bulkTransfer.valid'
    const messageParams = accountNo ? { accountNo } : {}

    return <Typography style={{ color: message ? 'red' : '' }}>{translate(messageKey, messageParams)}</Typography>
}

export default StatusMessageTable
