import { Pagination, PaginationProps } from 'antd'
import React from 'react'

import classes from './styles.module.less'

interface Props extends PaginationProps {
    totalItems: number
    currentPage: number
    onChangePage: (page: number) => void
}

const PaginationComponent = ({ totalItems, onChangePage, currentPage, ...rest }: Props) => {
    return (
        <div className={classes.root}>
            <Pagination
                onChange={onChangePage}
                total={totalItems || 1}
                defaultCurrent={1}
                current={currentPage || 1}
                {...rest}
            />
        </div>
    )
}

export default PaginationComponent
