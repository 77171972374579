// @ts-ignore
import { ROUTES } from '.'
import { LazyBulkTransferPage, LazyHealthyPage, LazyNotFoundPage, LazyMaintenancePage } from '@pages'

type PageRouteProps = {
    path: string
    element: JSX.Element
}

export const PagesRoutes = [
    // COMPONENTS
    {
        path: ROUTES.HOME,
        element: <LazyBulkTransferPage />
    },
    {
        path: ROUTES.HOME_EN,
        element: <LazyBulkTransferPage />
    },
    {
        path: ROUTES.HOME_VI,
        element: <LazyBulkTransferPage />
    },
    {
        path: ROUTES.NOT_FOUND,
        element: <LazyNotFoundPage />
    },
    {
        path: ROUTES.NOT_FOUND_VI,
        element: <LazyNotFoundPage />
    },
    {
        path: ROUTES.NOT_FOUND_EN,
        element: <LazyNotFoundPage />
    },
    {
        path: ROUTES.MAINTENANCE,
        element: <LazyMaintenancePage />
    },
    {
        path: ROUTES.MAINTENANCE_VI,
        element: <LazyMaintenancePage />
    },
    {
        path: ROUTES.MAINTENANCE_EN,
        element: <LazyMaintenancePage />
    },
    {
        path: ROUTES.HEALTH,
        element: <LazyHealthyPage />
    }
] as PageRouteProps[]
