import { Modal, Typography } from 'antd'
import { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingOutlined } from '@ant-design/icons'

import CloseIcon from '@assets/icons/close-modal.svg'
import { encodePhoneNumber, generateFormData } from '@utils/helpers'
import { CONFIG_APP, EXPIRED_SECONDS, STATUS_CODE } from '@constants/app'

import useSteps from '@hooks/useSteps'
import { Button, OTPInput } from '@components'
import { useAppSelector } from '@reduxs/hooks'
import { useCacheFileMutation } from '@services/CacheFileApp/CacheFileAPI'
import { useSubmitFileMutation } from '@services/BulkTransferApp/UploadFileAPI'
import { getSessionStorageItem } from '@utils/sessionStorage'

import classes from './styles.module.less'

type Props = {
    open: boolean
    onClose: () => void
    mobile: string
    userName: string
    otpDefault?: string[]
}

const styleButtonProps = {
    style: {
        display: 'none'
    }
}

const OTPInputModal = ({ open, onClose, mobile, userName, otpDefault }: Props): JSX.Element => {
    const { t: translate } = useTranslation()

    const { next, setSteps, steps } = useSteps()
    const { currentLang } = useAppSelector((state) => state.settings)
    const [cacheFileMutate] = useCacheFileMutation()
    const [submitMutate, { isLoading }] = useSubmitFileMutation()

    const [error, setError] = useState<string>('')
    const [otp, setOtp] = useState<string[]>([])
    const [isResetTimer, setIsResetTimer] = useState<boolean>(false)
    const [remainingTime, setRemainingTime] = useState<number>(EXPIRED_SECONDS)

    const onCloseModal = useCallback(() => {
        onClose()
        setOtp([])
        setRemainingTime(EXPIRED_SECONDS)
    }, [onClose])

    const encryptMobile = encodePhoneNumber(mobile)

    const handleClick = () => {
        const auth = getSessionStorageItem(CONFIG_APP.AUTH_INFO)
        const payload = {
            tran_id: steps.transactionId,
            otp: otp.join(''),
            tran_multi_desc: steps.purpose,
            lang: currentLang.toUpperCase(),
            user_name: userName,
            session: auth.sessionId
        }
        submitMutate(payload)
            .unwrap()
            .then((response) => {
                const { data, code, message } = response
                if (code !== STATUS_CODE.SUCCESS) return setError(message.text)
                const payloadHeader = {
                    app_key: data.app_key,
                    session_id: auth.sessionId,
                    access_token: data.access_token
                }
                setSteps({ ...steps, dataCompleted: data, isCompleted: true, tranMultiId: data.tran_multi_id })
                const { formData } = generateFormData({
                    file: steps.fileLocal,
                    file_name: steps.fileLocal.name,
                    checksum: steps.checksum
                })
                cacheFileMutate({ body: formData, payloadHeader })
                next()
            })
            .catch(() => setError(translate('messages.error.unknownError')))
    }

    const onChange = useCallback((otp: string[]) => {
        setOtp(otp)
    }, [])

    useEffect(() => {
        if (open) {
            const timerId = setInterval(() => {
                setRemainingTime((prevTime) => {
                    if (prevTime === 0) {
                        clearInterval(timerId)
                        onCloseModal()
                        return 0
                    }
                    return prevTime - 1
                })
            }, 1000)
            return () => clearInterval(timerId)
        }
        setIsResetTimer(false)
        setRemainingTime(EXPIRED_SECONDS)
        setError('')
    }, [open, onCloseModal])

    return (
        <Modal
            open={open}
            onCancel={onCloseModal}
            keyboard={false}
            maskClosable={false}
            width={600}
            okButtonProps={styleButtonProps}
            cancelButtonProps={styleButtonProps}
            closeIcon={<img src={CloseIcon} alt='icon' />}
            footer={[
                <div className={classes.buttonVerify} key='button-verify'>
                    <Button
                        type='primary'
                        onClick={handleClick}
                        disabled={otp.filter((item) => item).length < 6 || isLoading}
                    >
                        {isLoading && <LoadingOutlined />} {translate('buttons.verify')}
                    </Button>
                </div>
            ]}
            centered
        >
            <div className='modal__otp'>
                <Typography className={classes.title}>{translate('pages.bulkTransfer.enterOtpCode')}</Typography>
                <Typography className={classes.subTitle}>
                    {translate('pages.bulkTransfer.otpHasBeenSend', {
                        phone: encryptMobile
                    })}
                </Typography>
                <OTPInput onChange={onChange} isReset={isResetTimer} otpDefault={otpDefault} />
                {error && <Typography className={classes.textError}>{error}</Typography>}
                <Typography className={classes.expiredOtpIn}>
                    {translate('pages.bulkTransfer.expiredOtpIn')}{' '}
                    <span style={{ fontWeight: 700 }}>
                        {`${translate('pages.bulkTransfer.second', {
                            remainingTime
                        })}`}
                    </span>
                </Typography>
            </div>
        </Modal>
    )
}

export default memo(OTPInputModal)
