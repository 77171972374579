const en = {
    translation: {
        common: {
            currentLang: 'Tiếng Việt',
            noData: 'No data'
        },
        tabs: {
            uploadFile: 'Load file',
            tranDetail: 'Bulk file details',
            verify: 'Authentication',
            uploadFileCompleted: 'Complete'
        },
        buttons: {
            back: 'Back',
            continue: 'Continue',
            verify: 'Verify',
            uploadNewFile: 'Load new file',
            backToHome: 'Go to Homepage',
            cancel: 'Cancel',
            accept: 'Accept',
            ok: 'Ok',
            delete: 'Delete',
            reset: 'Reset',
            chooseFile: 'Choose a file',
            all: 'All',
            valid: 'Valid',
            inValid: 'Invalid',
            apply: 'Apply',
            goToUploadFile: 'Go to upload file'
        },
        inputs: {
            form: {
                username: 'Username',
                mobile: 'Mobile number',
                bulkTransferPurpose: 'Bulk transfer purpose'
            }
        },
        pages: {
            bulkTransfer: {
                loadBulkFile: 'Load bulk file',
                quicklyTransferMoney: 'Fast transfer to many beneficiary accounts from Excel file',
                dragFile: 'Or drag the file here',
                formatFile: 'File formats: .xls, xlsx, file size not exceed 1Mb',
                notice: 'Notes',
                noticePrompt:
                    'This function is for loading bulk file only. You will need to access VIB Checkout to complete your transaction.',
                uploadByRole: 'Only user has role inputter can upload excel file.',
                template: 'File template',
                use: 'Use the right',
                provider: 'given by VIB.',
                numberOfTransferItems:
                    ' The number of transfer items should not exceed 100 and not duplicated (account, bank, amount, content).',
                valid: 'Valid',
                invalid: 'Invalid',
                duplicateItems:
                    'Bulk file contains duplicated items (account number {{accountNo}}). Please check and try again.',
                order: 'No.',
                receiver: 'Recipient',
                accountNo: 'Account',
                amount: 'Amount',
                content: 'Description',
                banking: 'Bank',
                status: 'Status',
                tranDetail: 'Bulk transfer file details',
                authentication: 'Authentication',
                verifyPrompt: 'Please enter VIB Checkout Username and Mobile number',
                completedUploadFile: 'Bulk transfer successfully uploaded',
                totalTransaction: 'Total transfer items',
                totalMoneyTransactionValid: 'Total amount of valid items',
                totalTransactionValid: 'Total valid items',
                totalAmountValid: 'Total valid amount',
                transactionValid: 'Valid items',
                transactionInvalid: 'Invalid items',
                tutorialBelow:
                    'You can view bulk details and complete the transaction on VIB Checkout with below instruction.',
                scanQr: 'Scan QR code',
                screen: 'on the screen with',
                camera: 'camera of a mobile phone with ',
                installApp: 'VIB Checkout installed',
                or: 'Or follow the instructions',
                selectTransaction: 'Select Transfer',
                chooseToTransfer: 'Select Bulk transfer',
                chooseFromExcel: 'Select From Excel File ',
                chooseGroup: 'Select File & group',
                chooseTransactionPending: 'Select Pending item',
                purposeTransfer: 'Name bulk transfer',
                fieldNamePurpose: 'Naming helps you follow your bulk tranfers more easily on VIB Checkout',
                noticePurposePrompt1: 'Bulk file purpose helps you follow up the transaction on VIB Checkout with ease',
                noticePurposePrompt2:
                    'Purpose name must not exceed 35 characters and can only enter special characters ,.-+():\\_&?',
                enterOtpCode: 'Enter OTP',
                otpHasBeenSend: 'OTP has been sent to your mobile number {{phone}}',
                expiredOtpIn: 'OTP will expire after ',
                second: '{{remainingTime}} seconds',
                defaultValuePurpose: 'Bulk transfer on',
                uploading: 'Uploading...',
                napasTransactionProcessing:
                    '* Transactions will be processed via fast transfer (Napas) with supporting banks and transaction amount under VND 500 million. If connection to beneficiary bank via fast transfer is lost, transactions will be switched to normal method.',
                signInApp: 'Please log in VIB Checkout to confirm your bulk transfer'
            },
            notFound: {
                pageNotFound: 'Sorry, this page was not found',
                pageYouSearch: 'The page you searched for might be removed, changed or temporarily unavailable.'
            },
            maintenance: {
                system: 'System maintenance',
                tryAgain: 'The page vibcheckout.vib.com.vn is under maintenance. Please come back later.',
                inconvenience: ' We are sorry for the inconvenience.'
            }
        },
        breadcrumb: {
            digitalBanking: 'Digital banking',
            bulkTransfer: 'VIB Checkout bulk transfer'
        },
        footer: {
            copyRight: '© 2023 Vietnam International Bank (VIB)'
        },
        messages: {
            error: {
                unknownError: 'Something went wrong when fetching bulk information. Please try again.',
                duplicateItems:
                    'Bulk file contains duplicated items (account number {{accountNo}}). Please check and try again.',
                required: 'This field cannot be left blank. Please check again.',
                phoneInValid: 'The mobile number you entered is not in a valid format. Please check again.',
                noRegisterOtp: 'You have not registered any OTP method. Please call 18008180 for assistance.',
                usernameInvalid:
                    'The username you entered is not in a valid format. Please try again or call 18008180 for assistance.',
                purposeTransfer: 'Purpose should not exceed 35 characters. Allowed special characters ,.-+():\\_&?',
                FILE_NO: 'Order number must be digit or blank.',
                FILE_FORMAT: 'Bulk file must be Excel file & file size should not exceed {{size}}. Please try again.',
                FILE_NAME:
                    'File name must not contain special characters (except underscore, parentheses, hyphen, dot and space) and should not exceed 35 characters. Please try again.',
                FILE_ACCOUNT: 'Bulk file contains duplicated items (account number {{accountNo}}).',
                FILE_LABEL:
                    'Column name should not contain special characters and should not exceed 160 characters. Please try again.',
                FILE_MAX_COL: 'Bulk file should not contain more than {{maxColumn}} columns. Please try again.',
                FILE_AMOUNT_FORMAT: 'Transaction amount must be in the right format.',
                FILE_MIN_ROW: 'Bulk file should contain at least 02 transfer items. Please try again.',
                FILE_MAX_ROW: 'Bulk file should not contain more than 100 transfer items. Please try again.',
                FILE_DES_FORMAT: 'Description should not contain special characters, except ,.-+():\\_&?.',
                FILE_DES_LENGTH: 'Description should not exceed 120 characters.',
                FILE_ACCT_FORMAT: 'Beneficiary account should contain numbers and/or letters only.',
                FILE_ACCT_NAME_FORMAT: 'Beneficiary name should not contain special characters, except ,.-+():\\_&?.',
                FILE_BANK_FORMAT: 'Bank name should not contain special characters, except ,.-+():\\_&?.',
                FILE_AMOUNT_BLANK: 'Transaction amount must be filled.',
                FILE_ACCT_BLANK: 'Beneficiary account must be filled.',
                FILE_BANK_BLANK: 'Bank name must be filled.',
                FILE_ACCT_LENGTH: 'Beneficiary account should not exceed 25 characters.',
                FILE_ACCT_NAME_LENGTH: 'Beneficiary name should not exceed 50 characters.',
                FILE_ACCT_NAME_BLANK: 'Beneficiary name must be filled.',
                FILE_AMOUNT_VALUE: 'Transaction amount must be from 10,000 đ to 499,999,999 đ.',
                FILE_BANK_LENGTH: 'Bank name should not exceed 70 characters.',
                FILE_BANK_EXIST:
                    'Bulk transfer does not support this bank name. Please correct this bank name based on given bank list in the template file.'
            },
            title: {
                unknownError: 'An unknown error',
                fileNameInvalid: 'Invalid file name',
                fileInValid: 'Invalid bulk file',
                noRegisterOtp: 'No OTP method registered',
                inValidColumnLabel: 'Invalid column name'
            }
        }
    }
}

export default en
