import { Input, InputProps } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { EyeOutlined, EyeInvisibleOutlined, LoadingOutlined } from '@ant-design/icons'
import { PATTERN } from '@constants/app'

import classes from './styles.module.less'

interface Props extends InputProps {
    id?: string
    type?: string
    isLoading?: boolean
    label?: string
    placeholder?: string
    initialValue?: string
    autoComplete?: string
    allowOnlyNumber?: boolean
    endAdornmentPassword?: boolean
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const FloatInput = ({
    id,
    type,
    label,
    isLoading,
    placeholder = '',
    initialValue,
    autoComplete = 'off',
    allowOnlyNumber,
    onChange,
    endAdornmentPassword,
    ...props
}: Props) => {
    const [value, setValue] = useState<string>('')
    const [focus, setFocus] = useState<boolean>(false)
    const [showPassword, setShowPassword] = useState<boolean>(false)

    if (!placeholder) placeholder = label || ''

    const isOccupied = useMemo(() => focus || (value && value.length !== 0), [focus, value])

    const labelClass = useMemo(
        () => (isOccupied ? classes.asLabel : `${classes.label} ${classes.asPlaceholder}`),
        [isOccupied]
    )

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        if (allowOnlyNumber && !value.match(PATTERN.ALLOW_ONLY_NUMBER)) return null
        setValue(value)
        onChange && onChange(event)
    }

    const onBlur = (): void => {
        const getValue = (document.getElementById(id as string) as HTMLInputElement).value
        if (!getValue) setFocus(false)
    }

    const onFocus = (): void => setFocus(true)

    const onShowPassword = (): void => setShowPassword(!showPassword)

    useEffect(() => {
        if (initialValue) setValue(initialValue)
    }, [initialValue])

    return (
        <div className={classes.root} onBlur={onBlur} onFocus={onFocus}>
            <Input
                autoComplete={autoComplete}
                className={classes.floatingInput}
                onChange={handleOnChange}
                type={!showPassword ? type : 'text'}
                value={value}
                id={id}
                {...props}
            />

            {endAdornmentPassword && (
                <div className={classes.eyeIcon} onClick={onShowPassword}>
                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
            )}

            {isLoading && (
                <div className={classes.loading}>
                    <LoadingOutlined />
                </div>
            )}

            <label className={labelClass}>{isOccupied ? label : placeholder}</label>
        </div>
    )
}

export default FloatInput
