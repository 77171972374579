import { API_SERVICE, ENVIRONMENT } from '@constants/app'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'

const environment = process.env.REACT_APP_ENVIRONMENT

const BASE_WEB_HOST =
    environment === ENVIRONMENT.PROD || environment === ENVIRONMENT.PREPROD
        ? window.location.origin
        : `${window.location.origin}/${environment}`

export const baseQuery = fetchBaseQuery({
    baseUrl: `${BASE_WEB_HOST}/${API_SERVICE}/multi-transfer/`
})
