import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'

import { UploadFileAPI } from '@services/BulkTransferApp/UploadFileAPI'
import { CacheUploadFileAPI } from '@services/CacheFileApp/CacheFileAPI'
import { MaintenanceAPI } from '@services/MaintenanceApp/MaintenanceAPI'

import rootReducer from './reducer'

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .concat(UploadFileAPI.middleware)
            .concat(CacheUploadFileAPI.middleware)
            .concat(MaintenanceAPI.middleware)
    },
    devTools: true
})

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof store.getState>

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
