import { ENVIRONMENT } from '@constants/app'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'

const environment = process.env.REACT_APP_ENVIRONMENT

const myVibApiDomain = process.env.REACT_APP_MSME_API_DOMAIN?.startsWith('https')
    ? process.env.REACT_APP_MSME_API_DOMAIN
    : null

const BASE_MSME_API_DOMAIN =
    myVibApiDomain ||
    (environment === ENVIRONMENT.PROD || environment === ENVIRONMENT.PREPROD
        ? 'https://msmeapi.vib.com.vn'
        : 'https://msmeapi-nonprod.vib')

export const baseQuery = fetchBaseQuery({
    baseUrl: `${BASE_MSME_API_DOMAIN}/${environment}/msme-customer-file/api/v1/`
})
