import { Divider, Radio, RadioChangeEvent, Space } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@components'
import { STATUS_TABLE_VALUE } from '@constants/app'
import classes from './styles.module.less'

type Props = {
    onChangeStatusTable: (value: Array<boolean> | string) => void
    confirm: () => void
}

const FilterDropDown = ({ onChangeStatusTable, confirm }: Props): JSX.Element => {
    const { t: translate } = useTranslation()
    const [value, setValue] = useState(STATUS_TABLE_VALUE.ALL)

    const onChange = (event: RadioChangeEvent) => setValue(event.target.value)

    const handleDelete = () => {
        onChangeStatusTable(STATUS_TABLE_VALUE.EMPTY)
        setValue(STATUS_TABLE_VALUE.ALL)
        confirm()
    }

    const handleFilter = () => {
        switch (value) {
            case STATUS_TABLE_VALUE.ALL:
                onChangeStatusTable([true, false])
                break
            case STATUS_TABLE_VALUE.VALID:
                onChangeStatusTable([true])
                break
            case STATUS_TABLE_VALUE.INVALID:
                onChangeStatusTable([false])
                break
            default:
                onChangeStatusTable(STATUS_TABLE_VALUE.ALL)
        }
        confirm()
    }
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Radio.Group onChange={onChange} value={value} className={classes.radio}>
                    <Space direction='vertical' size={20}>
                        <Radio value={STATUS_TABLE_VALUE.ALL}>{translate('buttons.all')}</Radio>
                        <Radio value={STATUS_TABLE_VALUE.VALID}>{translate('buttons.valid')}</Radio>
                        <Radio value={STATUS_TABLE_VALUE.INVALID}>{translate('buttons.inValid')}</Radio>
                    </Space>
                </Radio.Group>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.actions}>
                <Button
                    type='text'
                    className={value ? classes.btnTextShow : classes.btnTextHidden}
                    onClick={handleDelete}
                >
                    {translate('buttons.delete')}
                </Button>
                <Button className={classes.btnApply} onClick={handleFilter} size='small' type='primary'>
                    {translate('buttons.apply')}
                </Button>
            </div>
        </div>
    )
}

export default FilterDropDown
